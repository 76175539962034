import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Loading from '../../Loading/Loading';
import QRCode from 'react-qr-code';

import Cookies from 'universal-cookie';

const { Config } = require('../../config');

const shareLink = "https://gymiai.com/dietregister/";

class DietShare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , lastId: null , mode: null}
        this.linkboxLoading = this.linkboxLoading.bind(this);
        this.loadingSuccess =  this.loadingSuccess.bind(this);
        this.loadingError = this.loadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.sendLink = this.sendLink.bind(this);
    }
    linkboxLoading() {
        document.getElementsByClassName("linkbox")[0].style.display = 'none';
        document.getElementsByClassName("linkbox")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("linkbox")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        } , "1000");
    }
    loadingError() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        }, "1000");
    }
    loadingReset() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        document.getElementsByClassName("loading--error")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'none';
        this.setState({mode:null});
    }

    sendLink() {
        let link = shareLink + this.state.lastId;
        let Phone = document.getElementsByClassName("phone")[0].value;
        this.linkboxLoading();
        axios.post(
            Config.BASE_URL + "/user/dietlinksms/",
            {
                diet_link: link,
                phone_number: Phone
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.loadingSuccess();
            }
        }).catch(error => {
            console.log(error);
            this.loadingError();
        });
    }

    render() {
        if(this.props.id == null) {
            return;
        }
        if(this.state.lastId == null || this.state.lastId != this.props.id) {
            this.setState({lastId: this.props.id , mode: null});
        }
        if(this.state.mode == null) {
            return (
                <div className='shareDiet'>
                    <button className='qrcode' onClick={() => {this.setState({mode:"qr"})}}>Qr</button>
                    <button className='link' onClick={() => {this.setState({mode:"link"})}}>لینک</button>
                </div>
            );
        }
        if(this.state.mode == "qr") {
            return(
                <div className='shareDiet'>
                    <QRCode value={shareLink + this.state.lastId} className='Qr'/>
                    <button className='back' onClick={() => {this.setState({mode:null})}}>برگشت</button>
                </div>
            );
        }
        if(this.state.mode == "link") {
            return(
                <div className='shareDiet'>
                    <div className='linkbox'>
                        <span className='topic'>لینک:</span>
                        <span className='link'>{shareLink + this.state.lastId}</span>
                        <span className='phoneTopic'>با ثبت شماره همراه هنرجوی خود ما لینک را در اختیارش قرار خواهیم داد</span>
                        <input className='phone' placeholder='شماره همراه'></input>
                        <button className='send' onClick={() => this.sendLink()}>ارسال لینک</button>
                        <button className='back' onClick={() => {this.setState({mode:null})}}>برگشت</button>
                    </div>
                    <div className='linkbox loading' style={{display:'none'}}>
                        <Loading successDialog={'لینک با موفقیت ارسال شد'} errorDialog={'خطایی پیش آمد دوباره تلاش کنید'}/>
                    </div>
                </div>
            );
        }
    }
}

export default DietShare