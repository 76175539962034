import ReactDOM from 'react-dom/client';
import React, { useEffect, useState } from 'react';

import logo from '../../../files/banners/GYMi/LOGO/Picture1.png'
import Viewboard from './Viewboard'
import cover from '../../../files/avocado_dark.png'

const task0style = {
    borderTopColor: 'white',
};

let dashboardstate = 1;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {val: props.value};
        this.initialize = this.initialize.bind(this);
        this.clicked = this.clicked.bind(this);
        this.hovered = this.hovered.bind(this);
    }


    initialize(s) {
        let items = document.getElementsByClassName("task");
        for (let i = 0;i < items.length; i++) {
            items[i].style.backgroundColor = "white";
            items[i].getElementsByTagName("p")[0].style.color = "black";
        }
        let id = (s == "trainers" ? 0 : s == "meals" ? 1 : s == "blog" ? 2 : s == "profile" ? 3 : 4);
        document.getElementsByClassName("task")[id].style.backgroundColor = "black";
        document.getElementsByClassName("task")[id].getElementsByTagName("p")[0].style.color = "white";
    }

    logoClick() {
        document.getElementsByClassName('dashboard--logo')[0].style.transform = "scaleX(-1)";
        setTimeout(() => {
            document.getElementsByClassName('dashboard--logo')[0].style.transform = "scaleX(1)";
        }, "300");
        setTimeout(() => {
            window.location.href = '/';
        }, "550");
    }

    clicked(s) {
        this.setState({val: s});
        this.initialize(s);
    }

    hovered(id) {
        let tmp = (this.state.val == "trainers" ? 0 : this.state.val == "meals" ? 1 : this.state.val == "blog" ? 2 : this.state.val == "profile" ? 3 : 4);
        if(tmp == id) return;
        document.getElementsByClassName("task")[id].style.backgroundColor = "rgb(20,55,25)";
        document.getElementsByClassName("task")[id].getElementsByTagName("p")[0].style.color = "white";
    }

    leaved(id) {
        let tmp = (this.state.val == "trainers" ? 0 : this.state.val == "meals" ? 1 : this.state.val == "blog" ? 2 : this.state.val == "profile" ? 3 : 4);
        if(tmp == id) return;
        document.getElementsByClassName("task")[id].style.backgroundColor = "white";
        document.getElementsByClassName("task")[id].getElementsByTagName("p")[0].style.color = "black";
    }

    hidedashboard() {
        // if(this.state.val != "mealmaker") return;
        // dashboardstate = 0;
        // setTimeout(() => {
        //     if(dashboardstate == 0) {
        //         document.getElementsByClassName("cpanel--dashboard")[0].style.left = "-20%";
        //         document.getElementsByClassName("cpanel--viewboard")[0].style.left = "-20%";
        //     }
        
        // }, 1000);
    }

    showdashboard() {
        // if(this.state.val != "mealmaker") return;
        // document.getElementsByClassName("cpanel--dashboard")[0].style.left = "0";
        // document.getElementsByClassName("cpanel--viewboard")[0].style.left = "0";
        // dashboardstate = 1;
    }

    render() {
        return(
            <div>
                <div className = "cpanel--dashboard" onLoad={() => this.initialize(this.state.val)} onMouseLeave={() => this.hidedashboard()} onMouseEnter={() => this.showdashboard()}>
                    <a className = "logo">
                        <img src={logo} className='dashboard--logo' onClick={() => this.logoClick()}></img>
                    </a>
                    <div className = "task" id='task-trainers' onClick={() => this.clicked("trainers")} onMouseOver={() => this.hovered(0)} onMouseLeave={() => this.leaved(0)} style={task0style}> <p>هنرجوها</p> </div>
                    <div className = "task" id='task-meals' onClick={() => this.clicked("meals")} onMouseOver={() => this.hovered(1)} onMouseLeave={() => this.leaved(1)}> <p>برنامه های تغذیه</p> </div>
                    <div className = "task" id='task-blog' onClick={() => this.clicked("blog")} onMouseOver={() => this.hovered(2)} onMouseLeave={() => this.leaved(2)}> <p>بلاگ</p> </div>
                    <div className = "task" id='task-profile' onClick={() => this.clicked("profile")} onMouseOver={() => this.hovered(3)} onMouseLeave={() => this.leaved(3)}> <p>پروفایل</p> </div>
                    <div className = "task" id='task-mealmaker' onClick={() => this.clicked("mealmaker")} onMouseOver={() => this.hovered(4)} onMouseLeave={() => this.leaved(4)}> <p>GYMi</p> </div>
                    <div className = "cover">
                        <img src={cover}></img>
                    </div>
                </div>
                <Viewboard  type = {this.state.val} dashboardstate = {this.state.dashboardstate}/>
            </div>
        );
    }
}

export default Dashboard;