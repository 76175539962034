import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Loading from '../../Loading/Loading';
import Diet from './Diet';
import dietcover from '../../../files/avocado_dark.png'
import DietShare from './DietShare';


const { Config } = require('../../config');

const pdfcss= "<head>\
                <meta charset=\"utf-8\">\
	            <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">\
	            <title>Diet-PDF</title>\
                <style type=\"text/css\" media=\"all\">\
                    html {\
                        margin: 0;\
                        padding: 0;\
                        /* direction: rtl; */\
                    }\
                    \
                    @font-face {\
                        font-family: \"main\";\
                        src: url(\"../../../files/Mizan.otf\");\
                        unicode-range: U+0-29, U+40-10FFFF;\
                    }\
                    \
                    @font-face {\
                        font-family: \"main\";\
                        src: url(\"../../../files/MizanBold.otf\");\
                        unicode-range: U+0-29, U+40-10FFFF;\
                        font-weight: bold;\
                    }\
                    \
                    @font-face {\
                        font-family: \"main\";\
                        src: url(\"../../../files/B-Nazanin.ttf\");\
                        unicode-range: U+30-39;\
                    }\
                    \
                    @font-face {\
                        font-family: \"main\";\
                        src: url(\"../../../files/B-Nazanin-Bold.ttf\");\
                        unicode-range: U+30-39;\
                        font-weight: bold;\
                    }\
                    \
                    * {\
                        font-family: \"main\";\
                    }\
                    \
                    body {\
                        margin: 0;\
                        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\
                            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\
                            sans-serif; */\
                        -webkit-font-smoothing: antialiased;\
                        -moz-osx-font-smoothing: grayscale;\
                    }\
                    \
                    /* code {\
                        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\
                        monospace;\
                    } */\
                    \
                    .pdf--body {\
                        display: flex;\
                        flex-direction: column;\
                        align-items: center;\
                        justify-content: center;\
                        justify-items: center;\
                        overflow-y: scroll;\
                        height: 100%;\
                    }\
                    \
                    .pdf--page {\
                        width: 210mm;\
                        height: 294mm;\
                        display: flex;\
                        justify-content: center;\
                        justify-items: center;\
                        justify-items: center;\
                        overflow-y: scroll;\
                        position: absolute;\
                    }\
                    \
                    .pdf--page img {\
                        height: 294mm;\
                        width: 210mm;\
                        filter: brightness(30%);\
                        z-index: 1;\
                        position: inherit;\
                    }\
                    \
                    .pdf--page > div.firstpage--box {\
                        width: 160mm;\
                        height: 80mm;\
                        filter: opacity(0.6);\
                        background-color: white;\
                        z-index: 2;\
                        position: inherit;\
                        margin-top: 107mm;\
                        border-radius: 15px;\
                        display: inline-block;\
                    }\
                    \
                    .pdf--page > div.firstpage--box > div.diet--name {\
                        filter: opacity(0.8);\
                        background-color: white;\
                        border: 2px solid;\
                        border-color: rgb(185, 55, 20);\
                        z-index: 2;\
                        position: inherit;\
                        margin-top: 10mm;\
                        margin-left: 10%;\
                        border-radius: 15px;\
                        width: 80%;\
                        height: 15%;\
                        display: flex;\
                        justify-content: center;\
                    }\
                    .pdf--page > div.firstpage--box > div.coach--name {\
                        filter: opacity(0.6);\
                        background-color: white;\
                        border: 2px solid;\
                        border-color: rgb(185, 55, 20);\
                        z-index: 2;\
                        position: inherit;\
                        margin-top: 30mm;\
                        margin-left: 55%;\
                        border-radius: 15px;\
                        width: 35%;\
                        height: 15%;\
                        display: flex;\
                        justify-content: center;\
                    }\
                    .pdf--page > div.firstpage--box > div.user--name {\
                        filter: opacity(0.6);\
                        background-color: white;\
                        border: 2px solid;\
                        border-color: rgb(185, 55, 20);\
                        z-index: 2;\
                        position: inherit;\
                        margin-top: 30mm;\
                        margin-left: 10%;\
                        border-radius: 15px;\
                        width: 35%;\
                        height: 15%;\
                        display: flex;\
                        justify-content: center;\
                    }\
                    \
                    .daypage--table {\
                        top:10mm;\
                        position: inherit;\
                        z-index: 2;\
                        border: 2px solid;\
                        border-color: rgb(185, 55, 20);\
                        width: 200mm;\
                        border-spacing:0; /* Removes the cell spacing via CSS */ \
                        border-collapse: separate !important;\
                        border-radius: 10px;\
                    }\
                    \
                    .daypage--table tr {\
                        height: 10mm;\
                    }\
                    \
                    .daypage--table td {\
                        border: 2px solid;\
                    }\
                    \
                    .daypage--table tr.header {\
                        height: 13mm;\
                        background-color: black;\
                        color: white;\
                    }\
                    \
                    .daypage--table tr.header td {\
                        border-top:none;\
                        border-left:none;\
                        background-color: black;\
                        color: white;\
                        text-align: center;\
                        border-top-right-radius: 10px;\
                        border-color: rgb(185, 55, 20);\
                        font-size: 2vh;\
                    }\
                    \
                    .daypage--table td.index {\
                        width: 10mm;\
                        text-align:center;\
                        white-space:nowrap;\
                        background-color: white;\
                        /* background-color: rgb(185, 55, 20); */ \
                        color: black;\
                        border-color: rgb(185, 55, 20);\
                        writing-mode: vertical-rl;\
                        padding-left: 6mm;\
                        border-top: none;\
                        border-right: none;\
                        font-size: 1.5vh;\
                    }\
                    \
                    .daypage--table td.analysis {\
                        width: 40mm;\
                        border-top: none;\
                        border-left: none;\
                        text-align: right;\
                        background-color: rgb(30 , 30 , 30);\
                        background-color: white;\
                        color: white;\
                        color: black;\
                        border-color: rgb(185, 55, 20);\
                        direction: rtl;\
                        font-size: 1.5vh;\
                    }\
                    .daypage--table td.analysis > div.analysis--box {\
                        margin-left: 2%;\
                        width: 95%;\
                        height: 100%;\
                        display: flex;\
                        justify-content: center;\
                        border-style: solid;\
                        border-color: rgb(0, 125, 40);\
                        background-color: rgb(255, 245, 230);\
                        border-radius: 20px;\
                        border-width: 1.5px;\
                        font-size: 1.5vh;\
                        text-align: start;\
                        line-height: 2.1vh;\
                    }\
                    \
                    .daypage--table td.analysis > div.analysis--box > span.analysis--tag {\
                        width: 60%;\
                        text-align: center;\
                    }\
                    \
                    .daypage--table td.analysis > div.analysis--box > span.analysis--data {\
                        width: 30%;\
                        text-align: end;\
                    }\
                    .daypage--table td.shopping {\
                        width: 90mm;\
                        border-top: none;\
                        border-left: none;\
                        min-height: fit-content;\
                        /* background-image: url(\"../../../files/shop-icop.svg\"); */\
                        background-size: 10% 35\%;\
                        background-position: left center;\
                        background-repeat: repeat-y repeat-x;\
                        background-color: white;\
                    }\
                    .daypage--table td.shopping > div.shopping--flex {\
                        width: 90mm;\
                        height: fit-content;\
                        display: flex;\
                        flex-direction: column;\
                        justify-content: flex-start;\
                        flex-wrap:wrap;\
                        direction: rtl;\
                    }\
                    \
                    .daypage--table td.shopping > div.shopping--flex > div.shopping--box {\
                        margin: 1mm 3.6mm 1mm 1mm;\
                        max-width: 92%;\
                        min-width: 44%;\
                        height: fit-content;\
                        min-height: 15%;\
                        background-color: rgb(255, 240, 230);\
                        display: flex;\
                        justify-content: center;\
                        border-style: solid;\
                        border-color: rgb(28, 28, 177);\
                        background-color: rgb(250, 227, 227);\
                        border-radius: 5px;\
                        border-width: 1.5px;\
                        font-size: 1.5vh;\
                    }\
                    \
                    .daypage--table td.food {\
                        border-top: none;\
                        border-right: none;\
                        border-left: none;\
                        background-color: rgb(255, 240, 230);\
                    }\
                    .daypage--table td.food > div.foodname--box {\
                        width: 100%;\
                        height: fit-content;\
                        display: flex;\
                        justify-content: center;\
                    }\
                    .daypage--table td.food span {\
                        direction: rtl;\
                        white-space: pre-line;\
                        text-align: center;\
                        font-size: 2vh;\
                        width: 100%;\
                    }\
                    .daypage--table td.food img {\
                        display: block;\
                        width: 263px;\
                        max-height: 109px;\
                        filter: brightness(80%);\
                    }\
                    \
                    .daypage--table tr.daynumber {\
                        border-top: none;\
                        border-right: none;\
                        border-left: none;\
                        background-color: black;\
                        width: 100%;\
                        border-radius: 10px;\
                        border-collapse: separate !important;\
                    }\
                    \
                    .daypage--table tr.daynumber td {\
                        border:none;\
                        background-color: black;\
                        color: white;\
                        text-align: center;\
                        border-radius: 10px;\
                        font-size: 3vh;\
                    }\
                </style>\
            </head>";

class Diets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , diets:[] , catchData: false , dietsInfo:[] , currentDietId: null};
        this.openDietModal = this.openDietModal.bind(this);
    }

    dietenter(id) {
        let diet = "diet" + id;
        document.getElementById(diet).style.borderColor = "rgb(185, 55, 20)";
        document.getElementById(diet).getElementsByClassName('dietcover')[0].style.opacity = "0.6";
    }
    dietleave(id) {
        let diet = "diet" + id;
        document.getElementById(diet).style.borderColor = "rgb(0, 0, 0)";
        document.getElementById(diet).getElementsByClassName('dietcover')[0].style.opacity = "0.2";
    }
    get_diets_id() {
        let response = [] , dietsId = [] , infos = [];
        axios.get(
            Config.BASE_URL  +"/diets/alldiets/" + this.state.cookies.get("username"),
            // { crossDomain: true },
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            response = res.data;
            let len = response[0].length;

            for(let i = 0; i < len; ++i) {
                dietsId.push(response[0][i]);
                infos.push(response[1][i]);
            }
            this.setState({diets: dietsId , dietsInfo: infos , catchData: true});
        })
    }
    dietcmp(info , index) {
        return(
            <div className='diet' id={"diet"+index.toString()} onClick={() => this.openDietModal(index)} onMouseEnter={() => this.dietenter(index.toString())} onMouseLeave={() => this.dietleave(index.toString())}>
                <img className='dietcover' src={dietcover}></img>
                <p className='diet--name'>{info}</p>
            </div>
        );
    }
    dietslist() {
        let list = [];
        let len = this.state.diets.length;
        for(let i = 0; i < len; i += 1) {
            list.push(this.dietcmp(this.state.dietsInfo[i] , i));
        }
        // list.push(<div className='addchat' onClick={() => this.openAddChatModal()}><span>+</span></div>);
        // list.push(<div className='addchat--modal'>
        //             <div className='modal--addchatbox'>
        //                 <input className='addchat--input' placeholder='عنوان گفتگو را وارد کنید'></input>
        //                 <button className='addchat--button' onClick={() => this.addChat()}>تایید</button>
        //             </div>
        //           </div>
        // );
        list.push(<div className='diet--modal'>
                    <div className='modal--dietbox'>
                        <Diet id={this.state.currentDietId}/>
                    </div>
                    <div className='share' style={{opacity: "0" , display:"none"}}>
                        <button className='share--button' onClick={() => this.dietShare()}>اشتراک گذاری</button>
                    </div>
                </div>
        );
        list.push(<div className='shareDiet--modal'>
                    <div className='modal--shareDietbox'>
                        <DietShare id={this.state.currentDietId}/>
                    </div>
                </div>
        );
        return list;
    }

    openDietModal(index) {
        document.getElementsByClassName("diet--modal")[0].style.display = "flex";
        document.getElementsByClassName("share")[0].style.display = "flex";
        document.getElementsByClassName("diet--modal")[0].style.visibility = "visible";

        this.setState({currentDietId: this.state.diets[index]});
        setTimeout(() => {
            document.getElementsByClassName("modal--dietbox")[0].style.opacity = "1";
            document.getElementsByClassName("share")[0].style.opacity = "1";
        } , "1000");
    }

    dietShare() {
        // setTimeout(() => {
        //     let html = "<html>"+ pdfcss + "<body id=\"body\"><div id=\"root\"><div class=\"pdf--body\">" + document.getElementsByClassName("pdf--body")[0].innerHTML + "</div></div></body></html>";
        //     var printWindow = window.open('', '', 'height=600,width=1000,top=' + ((window.screen.height - 600) / 2) + ',left=' + ((window.screen.width - 1000) / 2));
        //     printWindow.document.write(html);
        //     printWindow.document.body.style.height = (2 * 297) + "mm";
        //     printWindow.document.body.setAttribute('style', 'font-family: "main" !important');
        //     printWindow.document.close();
        // }, "2000")
        document.getElementsByClassName("shareDiet--modal")[0].style.display = "flex";
        document.getElementsByClassName("shareDiet--modal")[0].style.visibility = "visible";

        setTimeout(() => {
            document.getElementsByClassName("modal--shareDietbox")[0].style.opacity = "1";
        } , "300");
    }

    render() {
        window.onclick = function(event) {
            if(event.target.className ==  "diet--modal") {
                document.getElementsByClassName("modal--dietbox")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("diet--modal")[0].style.display = "none";
                } , "300");
            }
            if(event.target.className == "shareDiet--modal") {
                document.getElementsByClassName("modal--shareDietbox")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("shareDiet--modal")[0].style.display = "none";
                } , "300");
            }
        }  

        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_diets_id();
            }, 500)
            return(
                <div className='diets' style={{padding: "0"}}>
                    <Loading />
                </div>
            );
        }
        return (
            <div className='diets'>
                {this.dietslist()}
            </div>
        );
    }
}

export default Diets