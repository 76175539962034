import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/logo2.png'

import Cookies from 'universal-cookie';

import '../styles/Verification.css';
import Loading from '../Loading/Loading';

const { Config } = require('../config');

class Verification extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , loadingSuccessDialog: "" , loadingErrorDialog: "" , smsSent: false , refreshDoc: false , lastTimer: null}
    } 

    verificationLoading() {
        document.getElementsByClassName("smsVerification")[0].style.display = 'none';
        document.getElementsByClassName("smsVerification")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("smsVerification")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess(dialog) {
        let elem = document.getElementsByClassName("smsVerification")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';

        let is = false;
        if(this.state.smsSent == false) {   
            is = true;
        }

        setTimeout(() => {
            this.loadingReset();
            if(!is) this.props.passaway();
        } , "1000");
    }
    loadingError(dialog) {
        let elem = document.getElementsByClassName("smsVerification")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';

        let is = false;
        if(this.state.smsSent == false) {   
            is = true;
        }

        setTimeout(() => {
            this.loadingReset();
            if(!is) this.setState({refreshDoc: !this.state.refreshDoc});
            else window.location.reload();
        }, "1000");
    }
    loadingReset() {
        let elem = document.getElementsByClassName("smsVerification")[1];
        elem.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        elem.getElementsByClassName("loading--error")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    sendSMS() {
        axios.get(
            Config.BASE_URL + "/user/verify/",
            {
                headers: {
                    'authorization': this.props.token,
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                setTimeout(() => {
                    this.setState({smsSent: true , lastTimer: Date.now()});
                }, "1000");
                setTimeout(() => {
                    document.getElementsByClassName("resend")[0].style.opacity = "0.6";
                    setTimeout(() => {
                        document.getElementsByClassName("resend")[0].style.opacity = "1";
                    }, "30000");
                }, "2000");
                this.loadingSuccess("پیامک با موفقیت به شماره همراه شما ارسال شد");
            }
        }).catch(error => {
            console.log(error);
            this.loadingError("در ارسال پیامک خطایی پیش آمده دوباره تلاش کنید");
        });
    }
    sendVerification() {
        let Code = document.getElementsByClassName("smsVerification")[0].getElementsByClassName("code")[0].value;
        this.verificationLoading();
        axios.post(
            Config.BASE_URL + "/user/verify/",
            {
                code: Code
            },
            {
                headers: {
                    'authorization': this.props.token,
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.loadingSuccess("تایید شد برای ثبت رژیم وارد حساب خود شوید");
            }
        }).catch(error => {
            console.log(error);
            this.loadingError("کد معتبر نمیباشد دوباره تلاش کنید");
        });
    }

    resend() {
        //30s
        if(Math.floor((Date.now() - this.state.lastTimer) / 1000) > 30) {
            this.setState({smsSent: false});
            document.getElementsByClassName("resend")[0].style.borderColor = "rgb(150, 150, 0)";
            setTimeout(() => {
                document.getElementsByClassName("resend")[0].style.borderColor = "white";
            }, '500');
        }
    }

    justifyPhone() {
        document.getElementsByClassName("smsVerification")[0].style.opacity = "0";
        setTimeout(() => {
            document.getElementsByClassName("smsVerification")[0].style.display = "none";
            document.getElementsByClassName("smsVerification")[0].style.display = "flex";
            document.getElementsByClassName("smsVerification")[0].style.opacity = "1";
        }, "300")
    }

    render() {
        if(this.props.token == "Token " || this.props.token.length < 10) {
            return;
        }
        // console.log(this.props.token);
        if(this.state.smsSent == false) {
            return (
                <div className='verify body'>
                    <div className='smsVerification' style={{display: 'none'}}></div>
                    <div className='smsVerification loading' style={{display:'flex' , opacity: '1'}} onLoad={() => this.sendSMS()}>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                </div>
            );
        }
        return (
            <div className='verify body' key={this.state.refreshDoc}>
                <div className='smsVerification'>
                    <span className='topic'>کد تاییدیه به شماره همراه "{this.props.phone}" ارسال شده لطفا آن را وارد کنید</span>
                    <input className='code' placeholder='کد تاییدیه'></input>
                    <button className='verify' onClick={() => this.sendVerification()}>تایید</button>
                    <div className='buttonseperator'>
                        <button className='resend' onClick={() => this.resend()}>ارسال مجدد</button>
                        <button className='justifyPhone' onClick={() => this.justifyPhone()}>تغییر شماره همراه</button>
                    </div>
                    <button className='passaway' onClick={() => {this.props.passaway()}}>عبور از این مرحله</button>
                </div>
                <div className='justifyPhone' style={{display:'none'}}>
                    <input className='newPhone' placeholder='شماره همراه جدید'></input>
                    <button className='justifyPhone--button'>تایید</button>
                </div>
                <div className='smsVerification loading' style={{display:'none'}}>
                    <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                </div>
            </div>
        );
    }
}

export default Verification