import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Loading from '../../Loading/Loading';


import Cookies from 'universal-cookie';
import firstpageCover from '../../../files/avocado_dark.png'
import daypageCover from "../../../files/pdf_daypage.jpg"

const { Config } = require('../../config');

const mealsIndex = ["وعده اول","وعده دوم","وعده سوم", "وعده چهارم","وعده پنجم","وعده ششم","وعده هفتم","وعده هشتم"];

class Diet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , dietName: "" , dietMeals: null , catchData: false}
        this.firstpage = this.firstpage.bind(this);
        this.dietpage = this.dietpage.bind(this);
        this.get_diet_data = this.get_diet_data.bind(this);
        this.mealsection1 = this.mealsection1.bind(this);
        this.mealsection2 = this.mealsection2.bind(this);
        this.mealsection3 = this.mealsection3.bind(this);
        this.mealsection4 = this.mealsection4.bind(this);
    }

    get_diet_data() {
        let response , name , meals = [];
        axios.get(
            Config.BASE_URL + "/diets/diet/"+this.props.id,
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            response = res.data;
            // console.log(response);

            name = response.name;
            meals = response.meals;
            console.log(meals);
            this.setState({dietName: name , dietMeals: meals , catchData: true});
        })
    }
    firstpage() {
        return (<div className='pdf--page' style={{top: "0mm"}}>
                    <img src={firstpageCover}></img>
                    <div className='firstpage--box'>
                        <div className="diet--name"><span className="diet--name--txt">{this.state.dietName}</span></div>
                        <div className="coach--name"><span className="coach--name--txt"></span></div>
                        <div className="user--name"><span className="user--name--txt"></span></div>
                        <div className="diet--date"></div>
                    </div>
                </div>
        );
    }
    mealsection1(index) {
        let ingredients = this.state.dietMeals[index].ingredients.split('،');
        let len = ingredients.length;
        let shopping = [];
        for (let i = 0; i < len; i = i + 1) {
            shopping.push(<div className='shopping--box'><span className="shopping--data" style={{margin: "1mm"}}>{ingredients[i].trim()}</span></div>);
        }
        return(<tr>  
                    <td className='analysis' style={{borderBottom: "none"}}><div className="analysis--box"><span className="analysis--tag">کالری :</span><span className="analysis--data">{this.state.dietMeals[index].calories}</span></div></td>
                    <td className= 'shopping' rowSpan={4}><div className="shopping--flex">{shopping}</div></td>
                    <td className= 'food' style={{textAlign: 'center'}}><div className='food--box'><span className='foodname' style={{textAlign: 'center'}}>{this.state.dietMeals[index].food_name}</span></div></td>
                    <td className='index' rowSpan={4}>{mealsIndex[index]}</td>
                </tr>
        );
    }
    mealsection2(index) {
        return(<tr>
                    <td className='analysis' style={{borderBottom: "none"}}><div className="analysis--box"><span className="analysis--tag">کربوهیدرات :</span><span className="analysis--data">{this.state.dietMeals[index].carbohydrate}</span></div></td>
                    <td className= 'food' rowSpan={3} style={{borderColor:"rgb(185, 55, 20)"}}><div className='food--box'><span className='foodrecip'>{this.state.dietMeals[index].recip}</span></div></td>
                </tr>
        );
    }
    mealsection3(index) {
        return( <tr>
                    <td className='analysis' style={{borderBottom: "none"}}><div className="analysis--box"><span className="analysis--tag">پروتیین :</span><span className="analysis--data">{this.state.dietMeals[index].protein}</span></div></td>
                </tr>
        );
    }
    mealsection4(index) {
        return(<tr>
                    <td className='analysis'><div className="analysis--box"><span className="analysis--tag">چربی :</span><span className="analysis--data">{this.state.dietMeals[index].fat}</span></div></td>
                </tr>
        );
    }
    dietpage() {
        let meals = [];
        for(let i = 0; i < this.state.dietMeals.length; i += 1) {
            meals.push(this.mealsection1(i));
            meals.push(this.mealsection2(i));
            meals.push(this.mealsection3(i));
            meals.push(this.mealsection4(i));
        }
        return(<div className='pdf--page' style={{top: "calc(294mm + 8.5mm)", backgroundColor: "rgb(240, 240, 220)"}}>
                    <img src={daypageCover}></img>
                    <table className='daypage--table'>
                        <tr className='header'>
                            <td>آنالیز وعده</td>
                            <td>مواد مورد نیاز</td>
                            <td colSpan={2}>وعده ها</td>
                        </tr>
                        {meals}
                        <tr className='daynumber'>
                            <td colSpan={4}> روز ۱</td>
                        </tr>
                    </table>
                </div>
        );
    }

    render() {
        // if(this.state.lastId == null || this.state.lastId != this.props.id) {
        //     this.setState({lastId: this.props.id , catchData: false , streamDialog: null , streamLoading: false , messages:[]});
        // }
        if(this.props.id == null) {
            return;
        }
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_diet_data();
            }, 500)
            return(
                <Loading />
            );
        }

        return (
            <div className='diet'>
                <div className="pdf--body" id="pdf--body">
                    {this.firstpage()}
                    {this.dietpage()}
                </div>
            </div>
        );
    }
}

export default Diet