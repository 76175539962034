import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Loading from '../../Loading/Loading';
import Chat from './Chat';

import chatcover from '../../../files/avocado_dark.png'

const { Config } = require('../../config');


class Kahu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , gymiMode: null , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null};
        this.get_conversations_id = this.get_conversations_id.bind(this);
        // this.get_conversation_info = this.get_conversation_info.bind(this);
        this.make_conversation = this.make_conversation.bind(this);
        this.addChat = this.addChat.bind(this);
        this.rerender = this.rerender.bind(this);
    }
    rerender() {
        this.setState({cookies: new Cookies() , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null});
    }

    setAiMode(mode) {
        let revMode = (mode == "diet" ? "workout" : "diet");
        document.getElementsByClassName("mode " + revMode)[0].style.width = "0%";
        document.getElementsByClassName("mode " + revMode)[0].style.display = "none";
        document.getElementsByClassName("mode " + mode)[0].style.width = "100%";
        document.getElementsByClassName("mode " + mode)[0].style.color = "rgb(0,60,0)";
        document.getElementsByClassName("mode " + mode)[0].style.borderColor = "rgb(185, 55, 20)";
        document.getElementsByClassName("mode " + mode)[0].style.borderRadius = "0px";
        setTimeout(() => {
            this.setState({gymiMode: mode})
        }, "800");
    }


    chatenter(id) {
        let chat = "chat" + id;
        document.getElementById(chat).style.borderColor = "rgb(185, 55, 20)";
        // document.getElementById(meal).style.fontSize = "3.1vh";
        document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.6";
    }

    chatleave(id) {
        let chat = "chat" + id;
        if(this.state.conversationsInfo[parseInt(id)][0] == true) document.getElementById(chat).style.borderColor = "rgb(0, 150, 0)";
        else document.getElementById(chat).style.borderColor = "rgb(200, 180, 0)";
        // document.getElementById(meal).style.fontSize = "3vh";
        document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.2";
    }
    get_conversations_id() {
        let response = [] , conversationsId = [] , infos = [];
        console.log(this.state.cookies.get("user-token"));
        axios.get(
            Config.BASE_URL + "/chat/conversations/" + this.state.cookies.get("username"),
            // { crossDomain: true },
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            response = res.data;

            for(let i = 0; i < response.length; ++i) {
                conversationsId.push(response[i].id);
                let info = [];
                info.push(response[i].has_plan);
                info.push(response[i].name);
                infos.push(info);
            }
            this.setState({conversations: conversationsId , conversationsInfo: infos , catchData: true});
        })
    }
    chatcmp(info , index) {
        if(info[0] == true) {
            return(
                <div className='chat done' id={"chat"+index.toString()} onClick={() => this.openChatModal(index)} onMouseEnter={() => this.chatenter(index.toString())} onMouseLeave={() => this.chatleave(index.toString())}>
                    <img className='chatcover' src={chatcover}></img>
                    <p className='chat--name'>{info[1]}</p>
                </div>
            );
        }
        return(
            <div className='chat ongoing' id={"chat"+index.toString()} onClick={() => this.openChatModal(index)} onMouseEnter={() => this.chatenter(index.toString())} onMouseLeave={() => this.chatleave(index.toString())}>
                <img className='chatcover' src={chatcover}></img>
                <p className='chat--name'>{info[1]}</p>
            </div>
        );
    }
    chatslist() {
        let list = [];
        let len = this.state.conversations.length;
        for(let i = 0; i < len; i += 1) {
            if(this.state.conversationsInfo[i][0] == true) {
                list.push(this.chatcmp(this.state.conversationsInfo[i] , i));
            }
        }
        for(let i = 0; i < len; i += 1) {
            if(this.state.conversationsInfo[i][0] != true) {
                list.push(this.chatcmp(this.state.conversationsInfo[i] , i));
            }
        }
        list.push(<div className='addchat' onClick={() => this.openAddChatModal()}><span>+</span></div>);
        list.push(<div className='addchat--modal'>
                    <div className='modal--addchatbox'>
                        <input className='addchat--input' placeholder='عنوان گفتگو را وارد کنید'></input>
                        <button className='addchat--button' onClick={() => this.addChat()}>تایید</button>
                    </div>
                  </div>
        );
        list.push(<div className='chat--modal'>
                    <div className='modal--chatbox'>
                        <Chat id={this.state.currentChatId} parentRefresh={this.rerender}/>
                    </div>
                </div>
        );
        return list;
    }
    openChatModal(index) {
        document.getElementsByClassName("chat--modal")[0].style.display = "flex";
        document.getElementsByClassName("chat--modal")[0].style.visibility = "visible";
        // document.getElementsByClassName("modal--chatbox")[0].innerHTML = pdfhtml;

        this.setState({currentChatId: this.state.conversations[index]});
        setTimeout(() => {
            document.getElementsByClassName("modal--chatbox")[0].style.opacity = "1";
        } , "100");
    }
    openAddChatModal() {
        document.getElementsByClassName("addchat--modal")[0].style.display = "flex";
        document.getElementsByClassName("addchat--modal")[0].style.visibility = "visible";
        setTimeout(() => {
            document.getElementsByClassName("modal--addchatbox")[0].style.opacity = "1";
        } , "100");
    }
    make_conversation(name) {
        axios.post(
            Config.BASE_URL + "/chat/conversation",
            {
                "name": name
            },
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            this.setState({currentChatId: res.data.id});
            this.setState({catchData: false});
        })
    }
    addChat() {
        let s = document.getElementsByClassName("addchat--input")[0].value;
        let unique = true;
        for(let i = 0; i < this.state.conversationsInfo.length; i += 1) {
            if(s == this.state.conversationsInfo[i][1]) unique = false;
        }
        if(s == "" || unique == false) return;
        this.make_conversation(s);
    }

    render() {

        window.onclick = function(event) {
            if(event.target.className ==  "chat--modal") {
                document.getElementsByClassName("modal--chatbox")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("chat--modal")[0].style.display = "none";
                } , "300");
            }
            if(event.target.className == "addchat--modal") {
                document.getElementsByClassName("modal--addchatbox")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("addchat--modal")[0].style.display = "none";
                } , "300");
            }
            if (event.target.className ==  "endconv--modal" || event.target.className == "disagree") {
                document.getElementsByClassName("modal--endverify")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("endconv--modal")[0].style.display = "none";
                } , "300");
            }
            if (event.target.className == "delconv--modal" || event.target.className == "disagree") {
                document.getElementsByClassName("modal--delverify")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("delconv--modal")[0].style.display = "none";
                } , "300");
            }
        }  
        
        if(this.state.gymiMode == null) {
            return (
                <div className='kahu' style={{padding:"0"}}>
                    <button className='mode diet' onClick={() => this.setAiMode("diet")}>{"GYMi\nتغذیه"}</button>
                    <button className='mode workout' onClick={() => this.setAiMode("workout")}>{"GYMi\nتمرین"}</button>
                </div>
            );
        }
        if(this.state.gymiMode == "diet") {
            if(this.state.catchData == false) {
                setTimeout(() => {
                    this.get_conversations_id();
                }, 500)
                return(
                    <div className='kahu' style={{padding: "0"}}>
                        <Loading />
                    </div>
                );
            }
            return (
                <div className='kahu'>
                    {this.chatslist()}
                </div>
            );
        }
        if(this.state.gymiMode == "workout") {
            setTimeout(() => {
                this.setState({gymiMode: null});
            }, "5000")
            return (
                <div className='kahu'>
                     <div className='commingsoon'>
                        <span>
                            <ReactTyped 
                                strings={["به زودی"]} typeSpeed={100} showCursor={false} startDelay= {1000} backSpeed={100} loop
                                // style={{whiteSpace: 'pre-line' , color: props.color}}
                            />
                        </span>
                    </div>
                </div>
            );
        }
    }
}

export default Kahu