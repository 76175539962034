import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/banners/GYMi/LOGO/Picture1.png'
import '../styles/Loading/Loading.css'
import '../styles/Loading/Loading_v2.css'

class Loading extends React.Component { 
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className='loading'>
                <img className='loading--logo' src={logo} onLoad={() => this.logoanimation}></img>
                <p className='loading--success' style={{color:"rgb(0,180,0)"}}>{this.props.successDialog}</p>
                <p className='loading--error' style={{color:"rgb(180,0,0)"}}>{this.props.errorDialog}</p>
            </div>
        );
    }
}

export default Loading