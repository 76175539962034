import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Home/Home.css'
import '../styles/Home/Home_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import pdfDemo from '../../files/pdfDemo.png'
import Loading from '../Loading/Loading'

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , currentBanner: 0 , bannersCount: 0 , loadedCount: 0 , lastMove: Date.now()};
        this.linkfield = this.linkfield.bind(this);
        
    }

    fadeMenu() {
        var items = document.getElementsByClassName("menuitem");
        for (var i = 0; i < items.length; i++)
            items[i].style.opacity = "0.2";
    }
    brightMenu() {
        var items = document.getElementsByClassName("menuitem");
        for (var i = 0; i < items.length; i++)
            items[i].style.opacity = "1";
    }     
    linkfield() {
        if(this.state.cookies.get('user-token') == null) {
            return(
                <a href = "/signup" className = "menuitem link">ثبت نام/ورود</a>
            );
        }
        else {
            if(this.state.cookies.get('role') == "coach" || this.state.cookies.get('role') == "Coach" || this.state.cookies.get('role') == "Coache") {
                return(
                    <a href = "/Cpanel" className = "menuitem link">پنل کاربری</a>
                );
            }
            else {
                return(
                    <a href = "/Tpanel" className = "menuitem link">پنل کاربری</a>
                );
            }
        }
    }

    initialize() {
        let cnt = document.getElementsByClassName('banner').length;
        this.setState({bannersCount: cnt});
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        for (let i = 0; i < cnt; i = i + 1) {
            document.getElementsByClassName('banner ' + String(i))[0].style.opacity = "0";
        }
        for (let i = 0; i < cnt; i = i + 1) {
            document.getElementsByClassName('banner ' + String(i))[0].style.right = "0px";
        }
        // console.log("fuck");
        document.getElementsByClassName('banner 0')[0].style.opacity = "1";
        document.getElementsByClassName("boxBanners--loading")[0].style.display = 'none';
        setInterval(() => {
            this.sliderMove(1 , 0);
        }, "1000")
    }
    loadComplete() {
        if(this.state.loadedCount == this.state.bannersCount - 1) {
            document.getElementsByClassName("boxBanners--loading")[0].style.display = 'none';
            // console.log("fuck u");
            document.getElementsByClassName('banner 0')[0].style.opacity = "1";
        }
        this.setState({loadedCount: this.state.loadedCount + 1});
    }
    sliderMove(dir , flag) {
        if(flag == 1 || Date.now() - this.state.lastMove > 5000) {
            let newBanner = (this.state.currentBanner + dir + this.state.bannersCount)%this.state.bannersCount;
            this.setPosition(newBanner , dir);
            setTimeout(() => {
                this.moveBanner(this.state.currentBanner , dir);
                this.moveBanner(newBanner , dir);
                this.sliderRefresh(newBanner);
            } , "300");
            this.setState({lastMove: Date.now()});
        }
    }
    sliderRefresh(newBanner) {
        this.setState({currentBanner: newBanner});
        for(let i = 0; i < this.state.bannersCount; i = i + 1) {
            if(i != newBanner) document.getElementsByClassName("banner " + String(i))[0].style.opacity = "0";
        }
        for(let i = 0; i < this.state.bannersCount; i = i + 1) {
            document.getElementsByClassName("banner " + String(i))[0].style.right = "0px";
        }
    }

    moveBanner(id , dir) {
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let pos =  document.getElementsByClassName("banner " + String(id))[0].style.right;
        pos = pos.substring(0 , pos.length - 2);
        let currRight = parseInt(pos);
        document.getElementsByClassName("banner " + String(id))[0].style.right = String(currRight + dir * -1 * maxWidth) + "px";
    }
    setPosition(id , dir) {
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let pos = dir * maxWidth;
        document.getElementsByClassName("banner " + String(id))[0].style.right = String(pos) + "px";
        setTimeout(() => {
            document.getElementsByClassName("banner " + String(id))[0].style.opacity = "1";
        }, "300");
    }

    bannersElem() {
        var is = (window.screen.availHeight > window.screen.availWidth);
        const banners = (!is ? require.context('../../files/banners/GYMi/JPG/GYMi/Chosen', true) : require.context('../../files/banners/GYMi/LOGO/poster', true));
        const bannersList = banners.keys().map(banner => banners(banner));
        let bannersElement = [];
        for (let i = 0; i < bannersList.length; i = i + 1) {
            bannersElement.push(<img className={'banner ' + i} src={bannersList[i]} style={{opacity: "0"}} onLoad={() => this.loadComplete()}></img>)
        }
        return bannersElement;
    }

    render() {
        return (
            <div className = "home">
                <div className = "menubar" onMouseOver={this.fadeMenu} onMouseLeave={this.brightMenu}>
                    <div className = "menuitem logo"><img src={logo} onClick={() => {window.location.href = "/"}}></img></div>
                    <div className = "menuitem cover"><img src={cover}></img></div>
                    <this.linkfield />
                </div>
                <div className='boxBanners' onLoad={() => this.initialize()}>
                    {this.bannersElem()}
                    <span className='sliderLeft' onClick={() => this.sliderMove(-1 , 1)}>&lt;</span>
                    <div className='boxBanners--loading'>
                        <Loading />
                    </div>
                    <span className='sliderRight' onClick={() => this.sliderMove(1 , 1)}>&gt;</span>
                </div>
            </div>
        );
    }
}

export default Home;