import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../styles/Cpanel/Profile.css'
import '../../styles/Cpanel/Profile_v2.css'
import Loading from '../../Loading/Loading';
import { Config } from '../../config';


class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , sigRef: createRef() , catchedData: false , username: "" , firstname: "" , lastname: "" , email: "" , phone: "" , password: "" , verified: ""};

        this.clearCanvas = this.clearCanvas.bind(this);
    }

    clearCanvas() {
        this.state.sigRef.current.clear();
    }

    getData() {
        axios.get(
            Config.BASE_URL + "/user/userprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({catchedData: true , username: res.data.username , firstname: res.data.first_name , lastname: res.data.last_name , email: res.data.email , phone: res.data.phone_number , password: res.data.password , verified: res.data.is_verified});
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "500");
            return (
                <div className='profile'>
                    <Loading />
                </div>
            );
        }
        else {

            return (
                <div className='profile'>
                    <div className='info'>
                        <span className='username'>نام کاربری:<span className='text'>{this.state.username}</span></span>
                        <span className='email'>ایمیل:<span className='text'>{this.state.email}</span></span>
                        <span className='name'>نام:<span className='text'>{this.state.firstname + " " + this.state.lastname}</span></span>
                        <span className='phone'>تلفن همراه:<span className='text'>{this.state.phone}</span></span>
                        <button className='leave' onClick={() => {this.state.cookies.remove("user-token");this.state.cookies.remove("username");this.state.cookies.remove("role"); setTimeout(() => {window.location.href="/";}, "300")}}>خروج</button>
                        {/* <span className='activationCode'>کد فعالسازی(با در اختیار گذاشتن این کد با هنرجوهای خود به آنها امکان ثبت نام در بخش هنرجوهارا فراهم کنید)<span className='text'>{"\n" + this.state.code}</span></span> */}
                    </div>
                    <div className='forgotPassword'>
                        <input className='password curr' placeholder='رمز فعلی'></input>
                        <input className='password new' placeholder='رمز جدید'></input>
                        <button className='button'>تایید</button>
                    </div>
                    <div className='topic'>
                        <span className='topic'>با ثبت نماد یا امضای دلخواه خود در صفحه ی زیر آن را در برنامه های تمرینی یا تغذیه به اشتراک بگذارید</span>
                    </div>
                    <div className='canvas'>
                        <button className='canvas clear' onClick={() => this.clearCanvas()}>خالی کردن</button>
                        <SignatureCanvas penColor={'white'}
                            canvasProps={{className: 'sigCanvas'}}
                            backgroundColor={'rgb(50,50,50)'}
                            clearOnResize={'false'}
                            dotSize={2}
                            velocityFilterWeight={0.1}
                            ref={this.state.sigRef}
                        />
                        <button className='canvas send' onClick={() => this.sendCanvas()}>تایید نماد</button>
                    </div>
                </div>

            );
        }
    }
}

export default Profile