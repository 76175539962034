import { useParams } from "react-router-dom";
import DietRegister from "./DietRegister";

function WithParams() {
    let param = useParams();
    console.log(param.id);
    return (
        <DietRegister dietId={param.id}/>
    );
}

export default WithParams;