import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ReactDOM from 'react-dom';

import Home from './pages/Home/Home';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Cpanel from './pages/Cpanel/Cpanel';
import Tpanel from './pages/Tpanel/Tpanel';
import WithParams from './pages/DietRegister/WithParams';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path = "/" element = {<Home  />}/>
                <Route path = "/signup" element = {<Signup />}/>
                <Route path = "/login" element = {<Login />}/>
                <Route path = "/Cpanel" element = {<Cpanel />}/>
                <Route path = "/Tpanel" element = {<Tpanel />}/>
                <Route path = "/dietregister/:id" element = {<WithParams />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;