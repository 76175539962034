import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Signup/Signup.css'
import '../styles/Signup/Signup_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import Loading from '../Loading/Loading';
import Verification from './Verification';
import { useParams } from 'react-router-dom';

import '../styles/DietRegister/DietRegister.css';

const { Config } = require('../config');


class DietRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , userMode: "signup" , requestSent: false , log: "" , loadingSuccessDialog: "" , loadingErrorDialog: "" , verificationToken: "" , userPhone: ""};
        this.menuBar = this.menuBar.bind(this);
        this.startLoading = this.startLoading.bind(this);
        this.signupLoadingSuccess = this.signupLoadingSuccess.bind(this);
        this.signupLoadingError = this.signupLoadingError.bind(this);
        this.loginLoadingSuccess = this.loginLoadingSuccess.bind(this);
        this.loginLoadingError = this.loginLoadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.sendSignupData = this.sendSignupData.bind(this);
        this.sendLoginData = this.sendLoginData.bind(this);
        this.registerDiet = this.registerDiet.bind(this);
        this.changeUserMode = this.changeUserMode.bind(this);
        this.dietLoadingSuccess = this.dietLoadingSuccess.bind(this);
        this.dietLoadingError = this.dietLoadingError.bind(this);
        this.verificationLoad = this.verificationLoad.bind(this);
    }

    fadeMenu() {
        var items = document.getElementsByClassName("menuitem");
        for (var i = 0; i < items.length; i++)
            items[i].style.opacity = "0.2";
    }
    brightMenu() {
        var items = document.getElementsByClassName("menuitem");
        for (var i = 0; i < items.length; i++)
            items[i].style.opacity = "1";
    }  
    menuBar() {
        return (
            <div className = "menubar" onMouseOver={this.fadeMenu} onMouseLeave={this.brightMenu}>
                <div className = "menuitem logo"><img src={logo} onClick={() => {window.location.href = "/"}}></img></div>
                <div className = "menuitem cover"><img src={cover}></img></div>
                <a href = "/signup" className = "menuitem link">ثبت نام/ورود</a>
            </div>
        );
    }

    changeUserMode() {
        this.setState({userMode : "login"});
        this.verificationDown();
    }


    startLoading() {
        document.getElementsByClassName("form")[0].style.display = 'none';
        document.getElementsByClassName("form")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.opacity = "1";
        } , "100");
    }
    signupLoadingSuccess(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            this.verificationLoad();
        } , "1000");
    }
    signupLoadingError(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            // window.location.reload();
        }, "1000");
    }
    loginLoadingSuccess(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        } , "1000");
    }
    loginLoadingError(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.reload();
        }, "1000");
    }
    dietLoadingSuccess(dialog) {
        this.setState({loadingSuccessDialog : dialog , requestSent: true});
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = "/";
        } , "1000");
    }
    dietLoadingError(dialog) {
        this.setState({loadingErrorDialog : dialog , requestSent: true});
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            // window.location.href = "/";
        }, "1000");
    }


    loadingReset() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        document.getElementsByClassName("loading--error")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    verificationLoad() {
        document.getElementsByClassName("form")[1].style.opacity = '0';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.display = 'none';
            document.getElementsByClassName("form")[2].style.display = 'flex';
            setTimeout(() => {
                document.getElementsByClassName("form")[2].style.opacity = "1";
            } , "100");
        }, "100");
    }
    verificationDown() {
        // document.getElementsByClassName("form")[2].style.opacity = '0';
        setTimeout(() => {
            // document.getElementsByClassName("form")[2].style.display = 'none';
            document.getElementsByClassName("form")[0].style.display = 'flex';
            document.getElementsByClassName("form")[0].getElementsByClassName("username")[0].value = "";
            document.getElementsByClassName("form")[0].getElementsByClassName("password")[0].value = "";
            setTimeout(() => {
                document.getElementsByClassName("form")[0].style.opacity = "1";
            } , "100");
        }, "100");
    }

    registerDiet(t) {
        axios.post(
            Config.BASE_URL + "/diets/share",
            {
                diet_id: this.props.dietId
            },
            {
                headers: {
                    'authorization': t,
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            this.dietLoadingSuccess("رژیم به پنل شما اضافه شد");
            
        }).catch(error => {
            console.log(error);
            this.dietLoadingError("در ثبت رژیم برای حساب شما مشکلی پیش آمده دوباره تلاش کنید");
        });
    }

    sendSignupData() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;
        let Email = document.getElementsByClassName("email")[0].value;
        let Phone = document.getElementsByClassName("phone")[0].value;
        let Firstname = document.getElementsByClassName("firstname")[0].value;
        let Lastname = document.getElementsByClassName("lastname")[0].value;
        if(Username.length < 5) {
            this.setState({log: "نام کاربری نباید کمتر از ۵ حرف باشد"});
            return;
        }
        if(Password.length < 4) {
            this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
            return;
        }
        if(Password != document.getElementsByClassName("password--verify")[0].value) {
            this.setState({log: "رمز با تاییدیه آن برابر نمیباشد"});
            return;
        }
        if(Phone.length < 10) {
            this.setState({log: "شماره همراه معتبر نمیباشد"});
            return;
        }
        var pattern=/[\x00-\x7F]+/;
        if(!pattern.test(Username) || !pattern.test(Email) || !pattern.test(Phone) || !pattern.test(Password)) {
            this.setState({log: "جز در نام و نام خانوادگی خود از حروف و ارقام لاتین باید استفاده شود"});
            return ;
        }


        this.startLoading();
        axios.post(
            Config.BASE_URL + "/user/signup/",
            {
                username: Username,
                password: Password,
                email: Email,
                phone_number : Phone,
                first_name:Firstname,
                last_name:Lastname
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.setState({verificationToken: res.data.token , userPhone: Phone});
                this.signupLoadingSuccess("حساب کاربری با موفقیت ساخته شد");
            }
        }).catch(error => {
            console.log(error);
            this.signupLoadingError("خطایی پیش آمده دوباره تلاش کنید!");
        });
    }   
    sendLoginData() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;
        // if(Username.length < 5) {
        //     this.setState({log: "نام کاربری نباید کمتر از ۵ حرف باشد"});
        //     return;
        // }
        // if(Password.length < 4) {
        //     this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
        //     return;
        // }
        this.startLoading();
        axios.post(
            Config.BASE_URL + "/user/login/",
            {
              username: Username,
              password: Password
            },
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
              }
            }
        ).then(res => {
            console.log(res);
            this.state.cookies.set('user-token' , "Token " + res.data.token , {path: '/' , sameSite:'lax'});
            this.state.cookies.set('username' , Username, {path: '/' , sameSite:'lax'});
            this.state.cookies.set('role' , res.data.role, {path: '/' , sameSite:'lax'});
            console.log(this.state.cookies.get("role"));
            setTimeout(() => {
                this.registerDiet("Token " + res.data.token);
            }, "500");
            this.loginLoadingSuccess("خوش آمدید");
        }).catch(error => {
            console.log(error);
            this.loginLoadingError("خطایی پیش آمده دوباره تلاش کنید!");
        });
    }

    render() {
        if(this.state.cookies.get('user-token') != null) {
            if(this.state.requestSent == false) {
                setTimeout(() => {
                    this.registerDiet(this.state.cookies.get('user-token'));
                }, "500");
            }

            return(
                <div className='dietregister'>
                    <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog} />
                </div>
            );
        }
        else {
            if(this.state.userMode == "signup") {
                return (
                    <div className='dietregister'>
                        <div className = "signup">
                            {this.menuBar()}
                            <div className='form'>
                                <span className='signup--topic'>ثبت نام</span>
                                <input className='username' placeholder='نام کاربری'></input>
                                <input className='firstname' placeholder='نام'></input>
                                <input className='lastname' placeholder='نام خانوادگی'></input>
                                <input className='phone' placeholder='شماره تلفن'></input>
                                <input className='email' placeholder='ایمیل'></input>
                                <input className='password' placeholder='رمز کاربری'></input>
                                <input className='password--verify' placeholder='تایید رمز کاربری'></input>
                                <button className='signup--button' onClick={() => this.sendSignupData()}>تایید</button>
                                <span className='modelogin' onClick={() => {this.setState({userMode: "login"})}}>ثبت نام کردید؟ وارد شوید</span>
                                <span className='log--error'>
                                    <ReactTyped 
                                        strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                        style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                                    />
                                </span>
                            </div>
                            <div className='form loading' style={{display:'none'}}>
                                <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                            </div>
                            <div className='form verification' style={{display: 'none'}}>
                                <Verification passaway={this.changeUserMode} token={"Token " + this.state.verificationToken} phone={this.state.userPhone}/>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className='dietregister'>
                        <div className = "login">
                            {this.menuBar()}
                            <div className='form'>
                                <span className='login--topic'>ورود</span>
                                <input className='username' placeholder='نام کاربری'></input>
                                <input className='password' placeholder='رمز کاربری'></input>
                                <button className='login--button' onClick={() => this.sendLoginData()}>تایید</button>
                                <span className='modesignup' onClick={() => {this.setState({userMode: "signup"})}}>ثبت نام نکردید؟</span>
                                <span className='log--error'>
                                    <ReactTyped 
                                        strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                        style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                                    />
                                </span>
                            </div>
                            <div className='form loading' style={{display:'none'}}>
                                <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default DietRegister;